import React from 'react'
import { Link } from 'gatsby'
import Layout from '../components/indexLayout'

const CGV = () => (
  <Layout>
    <div className="page white-bg">
      <div className="container">
        
        <section className="hero-section">
          <div className="hero-section-66">
            <p className="firstbond-header">
                Conditions générales de vente
            </p>
            <p>
              Le site marchand <Link to="/">www.firstbond.boutique</Link> (ci après dénommé le “Site”) est un site de commerce électronique proposant la vente de panneaux composites aluminium fabriqués pour FIRSTBOND FRANCE (ci après dénommés les “Produits”) à toutes personnes naviguant sur le Site (ci après “Utilisateurs”).
            </p>
            <p>
              Nos conditions générales de vente sont accessibles sur le Site par l’Utilisateur au moment de la validation de la commande. Une fois validée, l’Utilisateur devient “Acheteur”.
              Conformément aux dispositions des articles 1316 à 1316-4 du Code Civil, auxquels s’ajoutent le Décret n°2001-272 du 30 Mars 2001 relatif à la signature électronique, il est signalé que la validation du bon de commande constitue une signature électronique qui a, entre le Site et l’Acheteur, la même valeur qu’une signature manuscrite et vaut preuve de l’intégralité de la commande et la réclamation des sommes dues en exécution de ladite commande.
            </p>
            <p>
              1. <a class="bleu gras" href="#1">Objet</a><br/>
              2. <a class="bleu gras"href="#2">Editeur du site</a> <br/>
              3. <a class="bleu gras"href="#3">Information et Libertés</a> <br/>
              4. <a class="bleu gras"href="#4">Les produits</a> <br/>
              5. <a class="bleu gras"href="#5">Les prix</a><br/>
              6. <a class="bleu gras"href="#6"> La commande</a><br/>
              7. <a class="bleu gras"href="#7">Le paiement </a><br/>
              8. <a class="bleu gras"href="#8">La livraison </a><br/>
              9. <a class="bleu gras"href="#9">Droit de rétractation</a> <br/>
              10. <a class="bleu gras"href="#10">Service Clien</a>t<br/>
              11. <a class="bleu gras"href="#11">Clauses diverses</a> <br/>
            </p>
            <p className="firstbond-header-h2">
              <a name="1">1. Objet</a>
            </p>
            <p>
              Les conditions générales de vente de ce Site ont pour but de définir  via le réseau Internet, les droits et obligations entre le Site et l’Acheteur  concernant la vente en ligne des produits exposés par le Site.
            </p>
            <p className="firstbond-header-h2">
              <a name="2">2. Editeur du Site</a>
            </p>
            <p>
              Eric Desvignes, entreprise inscrite au RCS Montpellier sous le n° 800 155 798 et n° de TVA intra-communautaire FR 64 800155798 domiciliée à Palavas-Les-Flots, 34250, France.
            </p>
            <p className="firstbond-header-h2">
              <a name="3">3. Information et Libertés</a>
            </p>
            <p>
              Conformément aux obligations de la loi 78-17 du 6 Janvier 1978 relative à “l’informatique, aux fichiers et aux libertés”, le Site Firstbond est déclaré auprès de la Commission Nationale de l’Informatique et des Libertés (CNIL) et vous disposez d’un droit d’accès, de rectification et de  suppression des données vous concernant que vous pouvez exercer en nous  écrivant à l’aide de notre formulaire de contact en ligne,ou par courrier adressé à:<br/>
              Service Client FIRSTBOND FRANCE, 1 rue des bains, 34250 Palavas-Les-Flots en mentionnant vos nom, prénom, adresse complète et numéro de client potentiellement.
            </p>
            <p className="firstbond-header-h2">
              <a name="4">4. Les produits</a>
            </p>
            <p>
              Les produits proposés sur le Site ne sont valables que dans la limite des stocks disponibles. En cas d’indisponibilité de l’un des Produits  après validation et paiement de votre commande, nous nous engageons à vous en  informer dans les 6 jours et à vous renseigner sur le délai d’attente pour la  réception de ce produit. Dans le cas où vous préfèreriez annuler (remboursement  par chèque sous quinzaine) ou échanger votre produit, prendre contact par téléphone, par Fax ou par mail, avec le Service Client. Les offres promotionnelles ne sont valables que dans la limite de la durée de validité et du stock disponible.
            </p>
            <p className="firstbond-header-h2">
              <a name="5">5. Les prix</a>
            </p>
            <p>
              Les prix sont indiqués en Euros, hors taxes comprises, hors frais de port et d’emballage et sont susceptibles de varier en cours d’année.
            </p>
            <p className="firstbond-header-h2">
              <a name="6">6. La commande</a>
            </p>
            <p>
              Pour passer une commande, il est nécessaire de remplir le formulaire avec votre Nom, votre code postale, ville ainsi que votre adresse email. Vous recevrez un devis gratuit, soit par téléphone, soit par email. Si le devis est satisfaisant, vous le confirmez par email ou par fax, pour ainsi valider votre commande.<br/>
            </p>
            <p className="firstbond-header-h2">
              <a name="7">7. Le paiement</a>
            </p>
            <p>
              Lors de la confirmation de la commande, l’acheteur a  le choix entre quatre modes de paiement:<br/>
              <ul>
                <li>par carte bleue</li>
                <li>par virement bancaire préalable à la livraison</li>
                <li>par chèque ou en espèces lors de la livraison, conformément à l'accord de vente</li>
              </ul>
            </p>
            <p className="firstbond-header-h2">
              <a name="8">8. La Livraison</a>
            </p>
            <p>
              L’expédition des Produits ne pourra s’effectuer qu’en France, Corse et Monaco. Il vous appartient de nous fournir avec exactitude tous les renseignements demandés nécessaire au bon acheminement de votre commande. Le service “Colissimo 48H” est assuré par La Poste. Firstbond France décline toute responsabilité en cas de délai de livraison trop long ainsi qu’en cas de perte des Produits commandés ou de grève. Les risques du transport sont supportés par l’Acheteur. Il est également précisé que la propriété des Produits commandés sera transférée à l’Acheteur au moment du Paiement total (prix facturé ainsi que les frais de livraison).
            </p>
            <p className="firstbond-header-h2">
              <a name="9">9. Droit de rétractation</a>
            </p>
            <p>
              Si dans votre commande, un produit ne corresponds pas à vos attentes, vous pouvez nous le renvoyer dans son conditionnement et emballage d’origine en parfait état, non déballé, au Service Client dans les 10 jours à compter de la réception. Nous pourrons, selon votre demande, vous l’échanger ou vous le rembourser par chèque 15 jours après réception du Service Client selon les modalités suivantes:
              <ul>
                <li>
                  Les frais d’envoi et d’éventuels retours vous seront remboursés si le produit est retourné du fait de notre responsabilité (produit manquant ou erreur)
                </li>
                <li>
                  Les frais de retour seront à votre charge mais le produit vous sera remboursé dans le cas où le produit nous est retourné sous votre initiative.
                </li>
              </ul>
            </p>
            <p className="firstbond-header-h2">
              <a name="10">10. Service Client</a>
            </p>
            <p>
              Pour toute information, question, paiement de commande et retours des Produits, contactez notre Service Client soit:<br/>
              <ul>
                <li>par courrier: Service Client FIRSTBOND FRANCE, 9 rue Substantion, 34250 Palavas-Les-Flots </li>
                <li>par email: <a href="mailto:contact@firstbond.boutique">contact@firstbond.boutique</a></li>
              </ul>
            </p>
            <p className="firstbond-header-h2">
              <a name="11">11. Clauses diverses</a>
            </p>
            <p>
              <ul>
                <li>
                  Tous nos produits sont conformes à la législation française en vigueur.
                </li>
                <li>
                  Firstbond France ne saurait être tenu responsable de la non exécution aux obligations de ces conditions générales de vente en cas de perturbation de grève totale ou partielle des services postaux, communications,  inondation, incendie. Firstbond France se réserve le droit de modifier à tout moment les présentes conditions générales de vente. Ces modifications ne seront pas applicables aux ventes réalisées antérieurement à la modification.
                </li>
              </ul>
            </p>

            <br/>
            <br/>

          </div>
        </section>

      </div>

    </div>

  </Layout>
)

export default CGV
